/**
 * Checks if passengers are added
 */
export default defineNuxtRouteMiddleware(() => {
    const passengerStore = usePassengerStore();
    const flowStore = useFlowStore();
    if (!passengerStore.passengers.length) {
        return navigateTo(getFirstFlowRoute(flowStore.productConfigCurrent?.steps));
    }
});
